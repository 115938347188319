@import '_variables';
@import '_menu';
@import '_header';
@import '_slim-select';
@import '_select';
@import '_chart';
@import '_temperature';
@import '_metodology';
@import '_proyectos';
@import '_footer';

body {
  margin: 0;
  line-height: 1.4;
}

* {
  box-sizing: border-box;
}

.forno {
  padding: 2rem 0;
}

.forno-container {
  width: 100%;
  grid-template-columns: 1fr;
  display: grid;
  margin: 2rem auto;
  grid-gap: 2rem;
  justify-items: stretch;
  align-items: stretch;
  color: #AC1545;
}

.forno-element {
  background-color: #f4e467;
  border-radius: .5rem;
  padding: 1rem;
}

.forno-text {
  font-size: 1rem;
  font-family: var(--roboto);
  display: block;
}

.forno-day {
  text-align: right;
  padding: .5rem;
  border-bottom: 1px solid #111;
}

.forno-record {
  font-size: 5rem;
  font-weight: bold;
}

.forno-year {
  font-size: 2rem;
}

@media only screen and (min-width: 48rem) {

  .forno-container {
    grid-template-columns: 1fr 1fr 1fr;
  }

}

.sponsor-card {
  font-family: var(--text);
  background: var(--white);
  box-shadow: rgba(0, 0, 0, .16) 0 4px 24px 0, rgba(0, 0, 0, .08) 0 2px 12px 0, rgba(64, 64, 76, .3) 0 0 0 .6px;
  padding: 1.5rem;
  border-radius: .5rem;
  a,
  iframe {
    display: block;
    margin-bottom: .5rem;
  }
}

.sponsor-card-text {
  margin-top: 0;
  font-size: .875rem;
}

@media only screen and (min-width: 64em) {

  .forno {
    min-height: 100vh;
  }
}
