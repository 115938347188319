 /* Menu inspired: https://codepen.io/BeanBaag/pen/XZRewP remove jQuery for JavaScript */
.burger {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 4px;
  z-index: 10;
}

.burger span {
  margin-bottom: 9px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}

.burger span,
.burger span:before,
.burger span:after {
  display: block;
  width: 30px;
  height: 3px;
  background-color: var(--black);
  outline: 1px solid transparent;
  -webkit-transition-property: background-color, -webkit-transform;
  transition-property: background-color, transform;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.burger span:before,
.burger span:after {
  position: absolute;
  content: "";
}

.burger span:before {
  top: -9px;
}

.burger span:after {
  top: 9px;
}

.burger.clicked span {
  background-color: transparent;
}

.burger.clicked span:before {
  -webkit-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg);
}

.burger.clicked span:after {
  -webkit-transform: translateY(-9px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg);
}

.burger.clicked span:before,
.burger.clicked span:after {
  background-color: var(--black);
}

.burger:hover {
  cursor: pointer;
}

/*  .navegacion
========================================== */
.navegacion {
  background-color: #B9DBDB;
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 25rem;
  width: 100%;
  padding: 1rem;
  overflow-y: auto;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: transform .55s cubic-bezier(.785, .135, .15, .86);
  transition: transform .55s cubic-bezier(.785, .135, .15, .86);
}
.navegacion.show {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.navegacion.show .navegacion-btn {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}
.navegacion.show .navegacion-btn:nth-child(1) {
  transition-delay: .15s;
}
.navegacion.show .navegacion-btn:nth-child(2) {
  transition-delay: .3s;
}
.navegacion.show .navegacion-btn:nth-child(3) {
  transition-delay: .45s;
}
.navegacion.show .navegacion-btn:nth-child(4) {
  transition-delay: .6s;
}
.navegacion.show .navegacion-btn:nth-child(5) {
  transition-delay: .75s;
}
.navegacion.show .navegacion-btn:nth-child(6) {
  transition-delay: .9s;
}
.navegacion.show .navegacion-btn:nth-child(7) {
  transition-delay: 1.05s;
}
.navegacion.show .navegacion-btn:nth-child(8) {
  transition-delay: 1.2s;
}
.navegacion.show .navegacion-btn:nth-child(9) {
  transition-delay: 1.35s;
}

.navegacion .navegacion-btn {
  margin-bottom: 5px;
  -webkit-transform: translateX(40px);
  transform: translateX(40px);
  opacity: 0;
  color: var(--black);
  font-family: var(--title);
  text-decoration: none;
  font-size: 1.25rem;
  display: block;
  font-weight: 600;
  padding: 10px 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;

}
.navegacion .navegacion-btn:last-of-type {
  margin-bottom: 0px;
}

/*  OVERLAY
========================================== */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  background-color: #b7ac7f;
  opacity: 0;
  visibility: hidden;
}
.overlay.show {
  opacity: .8;
  visibility: visible;
}

.menu--inola {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu--inola .menu__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.menu--inola .menu__item:last-child {
    margin-bottom: 0;
}

.menu--inola .menu__item-name {
    padding: 0 .25rem;
    color: #00032d;
}

.menu--inola .menu__item-name:before {
    content: '';
    position: absolute;
    top: calc(75% - 2px);
    left: 0;
    width: 100%;
    height: 4px;
    pointer-events: none;
    background: #d4546a;
    transform: scale3d(0, 1, 1);
    transform-origin: 100% 50%;
    transition: transform .5s;
    transition-timing-function: cubic-bezier(.8, 0, .2, 1);
}

.menu--inola .menu__item:hover .menu__item-name:before,
.menu--inola .menu__item:focus .menu__item-name:before {
    transform: scale3d(1, 1, 1);
    transform-origin: 0% 50%;
}

.menu--inola .menu__item-label:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--orange);
    transform-origin: 100% 50%;
    transition: transform .5s;
    transition-timing-function: cubic-bezier(.8, 0, .2, 1);
}

.menu--inola .menu__item:hover .menu__item-label:after,
.menu--inola .menu__item:focus .menu__item-label:after {
    transform: scale3d(0, 1, 1);
    transform-origin: 0% 50%;
}
