:root {
  --fontSystem: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Helvetica, sans-serif;
  --monospace: 'SFMono-Regular', Consolas, Menlo, Courier, monospace;
  --title: 'Playfair Display', serif;
  --text: 'Adamina', serif;
  --roboto: 'Roboto Condensed', sans-serif;
  --orange: #F35F3D;
  --circles: #261a41;
  --black: #111;
  --white: #fff;
  --shadowTooltip: 0 2px 9px 0 rgba(0, 0, 0, .2);
  --shadowCard: 0 2px 4px 0 rgba(0, 0, 0, .31), 0 2px 4px 0 rgba(50, 50, 58, .2);
  --fondoproyectos: #0c1e44;
  --project1bg: #FEE1C2;
  --project1txt: #952747;
  --project2bg: #354A57;
  --project2txt: #a5cf80;
  --project3bg: #EABAAB;
  --project3txt: #36152f;
  --project4bg: #798780;
  --project4txt: #1a182a;
  --project5bg: #995243;
  --project5txt: #e5f9fb;
  --project6bg: #A37E49;
  --project6txt: #05183f;
  --project7bg: #AAD7C7;
  --project7txt: #145669;
  --project8bg: #EABFB1;
  --project8txt: #670509;
  --project9bg: #fff6cb;
  --project9txt: #0e2153;
  --project11bg: #DF9245;
  --project11txt: #231736;
  --project12bg: #F4E467;
  --project12txt: #111;
}
